import React from 'react';
import { ConfigProvider, Tabs } from 'antd';
import { AimOutlined, LineChartOutlined, SlidersOutlined, CloseOutlined } from '@ant-design/icons';


const CustomTabs = ({ defaultActiveKey, children }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            colorPrimary: '#1890ff', // Customize the primary color
            itemHoverColor: '#40a9ff', // Customize the hover color
            itemSelectedColor: '#096dd9', // Customize the selected tab color
            itemHeight: '48px', // Customize the height of the tab item
            itemFontSize: '16px', // Customize the font size of the tab item
            barHeight: '3px', // Customize the height of the active tab bar
            barColor: '#096dd9', // Customize the color of the active tab bar
            itemColor: '#ffffff'
          },
        },
      }}
    >
      <Tabs defaultActiveKey={defaultActiveKey}>
        {React.Children.map(children, (child) => {
          let icon = null;
          if (child.key === '1') {
            icon = <AimOutlined />;
          } else if (child.key === '2') {
            icon = <LineChartOutlined />;
          } else if (child.key === '3') {
            icon = <SlidersOutlined />;
          } else if (child.key === '4') {
            icon = <CloseOutlined />;
          }
          
          return React.cloneElement(child, { tab: <span>{icon} {child.props.tab}</span> });
        })}
      </Tabs>
    </ConfigProvider>
  );
};

export default CustomTabs;
