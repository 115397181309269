import { React } from 'react';
import PlayerCard from '../views/playercard';
import PlayerTable from '../views/playertable';
import styles from './../css/playerspage.module.css'; // Convert CSS to module and import here
import playerstab from './../assets/images/Players_Tab.png'; // Importing the image
import devytab from './../assets/images/Devy_Players_Tab.png'; // Importing the image

import playerTab1 from './../assets/images/PlayerTab1.png'; // Importing the image
import playerTab2 from './../assets/images/PlayerTab2.png'; // Importing the image
import playerTab3 from './../assets/images/PlayerTab3.png'; // Importing the image
import PlayerCardMobile from '../views/playercardmobile';
import useIsMobile from '../services/useIsMobile';
import './../css/playerspage.css';
import { useEffect } from 'react';


function PlayersPage({ players, handleRowClick, selectedPlayer, setSelectedPlayer, isNFL = true }) {

    useEffect(() => {
        const handleScroll = () => {
            document.querySelectorAll('.fade-in').forEach((el) => {
                const rect = el.getBoundingClientRect();
                if (rect.top < window.innerHeight) {
                    el.classList.add('visible');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const isMobile = useIsMobile(); // Use the custom hook to detect screen size
    console.log(isMobile);

    return (
        <div className={styles.playerspage}>
            {/* Add the image at the very top */}
            <img
                src={isNFL ? playerstab : devytab} // Conditional rendering of the image source
                alt="Performance Overview"
                className={styles.performanceImage}
            />

            {/* New Section: Three Images in Asymmetrical Row */}
            <div className={styles.imageRow}>
                <img src={playerTab1} alt="Player Tab 1" className={styles.smallImage} />
                <img src={playerTab2} alt="Player Tab 2" className={styles.largeImage} />
                <img src={playerTab3} alt="Player Tab 3" className={styles.smallImage} />
            </div>

            <div className={styles.playerstooltext}>
                <h2 className={styles.playerstooltitle}>PLAYERS TOOL</h2>
                <p className={styles.playerstooldescription}>
                    Explore our comprehensive player database featuring detailed insights into player performance and strategy.
                    The table provides a quick overview of player tiers, startup ranks, buy/sell recommendations, and player types.
                    Click on any player to access an interactive popup with year-by-year metrics, helping you analyze their historical
                    performance and future potential. Whether you're building your roster or making trade decisions, this tool is designed
                    to give you the edge you need in your fantasy league.
                </p>
            </div>

            {/* Conditionally render PlayerCard or PlayerCardMobile based on screen size */}
            <div className={`${isMobile ? 'player-card-container-mobile' : styles.playerCardContainer} ${selectedPlayer ? '' : styles.hidden}`}>
                {selectedPlayer && (
                    isMobile ? (
                        <PlayerCardMobile player={selectedPlayer} onClose={() => setSelectedPlayer(null)} isNFL={isNFL} setSelectedPlayer={setSelectedPlayer} />
                    ) : (

                        <PlayerCard player={selectedPlayer} onClose={() => setSelectedPlayer(null)} isNFL={isNFL} setSelectedPlayer={setSelectedPlayer} />
                    
                    )
                )}
            </div>
            <div className={styles.tableContainer}>
                <PlayerTable data={players} onRowClick={handleRowClick} setSelectedPlayer={setSelectedPlayer} isNFL={isNFL} />
            </div>
        </div>
            );
}

            export default PlayersPage;
