import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { getPlayer, getActivePlayersSorted } from './services/playerservice';
import HeaderComponent from './views/headercomponent'; // Adjust the path as needed
import HomePage from './pages/HomePage';
import RostershipPage from './pages/RostershipPage';
import PerformancePage from './pages/Performance';
import DevyPerformancePage from './pages/devy/DevyPerformance';
import PlayersPage from './pages/PlayersPage';
import ComparePage from './pages/Compare';
import SimilarPlayersPage from './pages/SimilarPlayers';

function App() {
  const [players, setPlayers] = useState([]);
  const [devy, setDevy] = useState([]);

  useEffect(() => {
    async function fetchPlayers() {
      try {
        const data = await getActivePlayersSorted(true);
        setPlayers(data);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    }
    async function fetchDevy() {
      try {
        const data = await getActivePlayersSorted(false);
        setDevy(data);
      } catch (error) {
        console.error('Error fetching devy players:', error);
      }
    }

    fetchPlayers();
    fetchDevy();
  }, []);

  // Function to detect if the user is on a mobile device
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);

  return (
    <Router>
      <Layout players={players} devy={devy} isMobile={isMobile} />
    </Router>
  );
}

function Layout({ players, devy, isMobile }) {
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const handleRowClick = async (record) => {
    try {
      const playerInfo = await getPlayer(record.id, true);
      setSelectedPlayer(playerInfo);
    } catch (error) {
      console.error('Error fetching player info:', error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    setSelectedPlayer(null); // Reset selected player on route change
  }, [location]);

  return (
    <>
      <HeaderComponent isMobile={isMobile} />
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/players"
            element={(
              <PlayersPage 
                isMobile={isMobile}
                players={players}
                isNFL={true}
                handleRowClick={handleRowClick}
                selectedPlayer={selectedPlayer}
                setSelectedPlayer={setSelectedPlayer}
              />
            )}
          />
          <Route path="/performance" element={<PerformancePage isMobile={isMobile} />} />
          <Route path="/rostership" element={<RostershipPage isMobile={isMobile} />} />
          <Route
            path="/compare"
            element={<ComparePage key="nfl-compare" players={players} isNFL={true} />}
          />
          <Route
            path="/similar"
            element={<SimilarPlayersPage key="nfl-similar" players={players} isNFL={true} />}
          />
          <Route
            path="/devy/performance"
            element={<DevyPerformancePage players={players} />}
          />
          <Route
            path="/devy/players"
            element={(
              <PlayersPage 
                isMobile={isMobile}
                players={devy}
                isNFL={false}
                handleRowClick={handleRowClick}
                selectedPlayer={selectedPlayer}
                setSelectedPlayer={setSelectedPlayer}
              />
            )}
          />
          <Route
            path="/devy/compare"
            element={<ComparePage key="devy-compare" players={devy} isNFL={false} />}
          />
          <Route
            path="/devy/similar"
            element={<SimilarPlayersPage key="devy-similar" players={devy} isNFL={false} />}
          />
          <Route path="*" element={<h2>404 Not Found</h2>} />
        </Routes>
      </div>
    </>
  );
}

export default App;
