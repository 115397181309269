import React, { useState, useEffect, useRef } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { ConfigProvider, Menu } from 'antd';
import { getPlayersByTeam, getPlayer } from '../services/playerservice';

interface PlayerMenuProps {
  firstName: string;
  lastName: string;
  position: string;
  team: string;
  setSelectedPlayer: React.Dispatch<React.SetStateAction<any>>; // Define setSelectedPlayer type
}

const PlayerMenu: React.FC<PlayerMenuProps> = ({ firstName, lastName, position, team, setSelectedPlayer }) => {
  const abbr = `${position} - ${firstName.charAt(0)}. ${lastName}`;

  const [current, setCurrent] = useState('mail');
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [menuWidth, setMenuWidth] = useState<number>(0);
  const abbrRef = useRef<HTMLDivElement>(null);
  const [playerData, setPlayerData] = useState<{ id: number, name: string }[]>([]); // State to hold player data

  const onClick: MenuProps['onClick'] = async (e) => {
    setCurrent(e.key);
    const clickedPlayer = playerData.find(player => `name:${player.id}` === e.key); // Find the player object from playerData based on the clicked key
    if (clickedPlayer) {
      const playerInfo = await getPlayer(clickedPlayer.id, false);
      setSelectedPlayer(playerInfo);
    }
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    if (abbrRef.current) {
      setMenuWidth(abbrRef.current.offsetWidth + 70); // Add some padding
    }

    if(team !== 'FA') {
      // Fetch data asynchronously
    getPlayersByTeam(team).then((data) => {
      // Map player data to id and name
      const playerInfo = data.map(player => ({
        id: player.id,
        name: `${player.first_name} ${player.last_name}`
      }));
      setPlayerData(playerInfo);
    }).catch((error) => {
      console.error('Error fetching players:', error);
    });
    }
    
  }, [abbr, team]);

  useEffect(() => {
    // Filter out the selected player from the playerData
    const filteredPlayerData = playerData.filter(
      player => !(player.name === `${firstName} ${lastName}`)
    );

    // Update menu items whenever playerData changes
    const items: MenuProps['items'] = [
      {
        label: abbr,
        key: 'SubMenu',
        icon: <CaretDownOutlined />,
        children: filteredPlayerData.map(player => ({
          label: player.name,
          key: `name:${player.id}`, // Use player ID as the key
        })),
      },
    ];

    setMenuItems(items);
  }, [playerData, firstName, lastName]);

  const [menuItems, setMenuItems] = useState<MenuProps['items']>([]); // State to hold menu items

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            darkItemColor: 'rgba(211,212,213, 1)',
            darkItemHoverColor: '#ffffff',
            darkItemSelectedColor: '#ffffff',
            darkGroupTitleColor: 'rgba(255, 0, 0, 0.65)',
            darkItemSelectedBg: 'transparent', // Add this line
            darkPopupBg: '#1b1b1b',
            itemHeight: 30,
          },
        },
      }}
    >
      <div ref={abbrRef} style={{ visibility: 'hidden', position: 'absolute', whiteSpace: 'nowrap' }}>
        {abbr}
      </div>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={menuItems} // Use menuItems state to dynamically render menu items
        theme="dark"
        style={{
          width: `${menuWidth}px`,
          background: 'rgb(36,41,44)',
          fontSize: "1em",
          border: '1.5px solid rgb(55,59,63)',
          height: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
        triggerSubMenuAction="click"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />
    </ConfigProvider>
  );
};

export default PlayerMenu;
