export default function calculateAge(playerinfo, isNFL = true) {
  if (isNFL) {
    const dateString = playerinfo.birthday
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the birthdate hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }
  else {
    const recruitYear = parseInt(playerinfo.recruityear, 10); // Convert to integer
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0 = January, 11 = December

    // Determine the academic year adjustment
    const academicYear = currentMonth >= 7 ? currentYear : currentYear - 1; // If it's August (7) or later, we're in the next academic year
    const yearsSinceRecruit = academicYear - recruitYear + 1; // Calculate the number of years since the recruit year

    // Convert the year number to ordinal (1st, 2nd, 3rd, etc.)
    const ordinalSuffixes = ["th", "st", "nd", "rd"];
    const v = yearsSinceRecruit % 100;
    const suffix =
      ordinalSuffixes[(v - 20) % 10] || ordinalSuffixes[v] || ordinalSuffixes[0];
    return `${yearsSinceRecruit}${suffix}`;
  }
}
