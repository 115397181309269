import React, { useEffect, useState } from 'react';
import './../css/similarplayer.css'; // Import your CSS file

// Easing function (ease-in-out cubic)
const easeInOutCubic = (t) => {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
};

// Function to calculate color based on score
const getColorFromScore = (score) => {
    if (score <= 0.6) {
        return 'rgb(255, 0, 0)'; // Red for 60% and below
    } else if (score <= 0.7) {
        return 'rgb(255, 165, 0)'; // Orange for scores between 60% and 70%
    } else if (score <= 0.8) {
        return 'rgb(255, 255, 0)'; // Yellow for scores between 70% and 80%
    } else if (score <= 0.9) {
        return 'rgb(144, 238, 144)'; // Light Green for scores between 80% and 90%
    } else {
        return 'rgb(0, 128, 0)'; // Dark Green for scores 90% and above
    }
};

const SimilarPlayerWidget = ({ similarPlayers, selectedPlayer, selectedYear, map }) => {
    const [animatedFill, setAnimatedFill] = useState(Array(similarPlayers.length).fill(0));

    // Animate the fill circle on load with easing
    useEffect(() => {
        similarPlayers.forEach((player, index) => {
            const score = player.similarity_score * 100; // The target fill percentage
            const duration = 2000; // Duration of the animation (in ms)
            const stepTime = 10; // Step interval (in ms)

            let currentTime = 0;

            const animateFill = () => {
                currentTime += stepTime;
                const progress = Math.min(currentTime / duration, 1); // Ensure progress doesn't exceed 1
                const easedProgress = easeInOutCubic(progress); // Apply easing function

                setAnimatedFill((prev) => {
                    const updatedFill = [...prev];
                    updatedFill[index] = easedProgress * score; // Use eased progress for smooth animation
                    return updatedFill;
                });

                if (progress < 1) {
                    setTimeout(animateFill, stepTime); // Continue animation until complete
                }
            };

            setTimeout(animateFill, index * 300); // Stagger animation for each player
        });

    }, [similarPlayers]);

    if (!similarPlayers.length) {
        return null;
    }

    // Extract the "Year N" part from the label in the map
    const yearLabel = map.find(entry => entry.value === selectedYear)?.label || '';
    const yearNum = yearLabel.match(/\(Year (\d+)\)/)?.[1] || ''; // Extract N from "Year N"

    return (
        <div className="similar-player-widget">
            <h3>{selectedPlayer.first_name} {selectedPlayer.last_name}'s Year {yearNum} Comps are...</h3>
            <div className="most-similar-player">
                <div className="outer-circle">
                    <div
                        className="thin-circle"
                        style={{
                            background: `conic-gradient(
                                ${getColorFromScore(similarPlayers[0].similarity_score)} 0% ${animatedFill[0]}%,
                                transparent ${animatedFill[0]}% 100%
                            )`,
                        }}
                    ></div>
                    <div className="inner-circle">
                        <p className="percentage">{(similarPlayers[0].similarity_score * 100).toFixed(0)}%</p>
                        <p className="similarity-text">SIMILARITY</p>
                    </div>
                </div>
                <p className='topsimilarplayer'>{similarPlayers[0].player_name} Year {similarPlayers[0].yearnum} ({similarPlayers[0].year})</p>
            </div>

            <div className="player-grid">
                {similarPlayers.slice(1).map((player, index) => (
                    <div key={player.playerid} className="player-item">
                        <div className="outer-circle">
                            <div
                                className="thin-circle"
                                style={{
                                    background: `conic-gradient(
                                        ${getColorFromScore(player.similarity_score)} 0% ${animatedFill[index + 1]}%,
                                        transparent ${animatedFill[index + 1]}% 100%
                                    )`,
                                }}
                            ></div>
                            <div className="inner-circle">
                                <p className="percentage-smaller">{(player.similarity_score * 100).toFixed(0)}%</p>
                                <p className="similarity-text-smaller">SIMILARITY</p>
                            </div>
                        </div>
                        <div className='lower-items'>{player.player_name}</div>
                        <div className='lower-items'>Year {player.yearnum} ({player.year})</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SimilarPlayerWidget;
