import React, { useState } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { ConfigProvider, Menu } from 'antd';
import type { MenuProps } from 'antd';
import './../App.css'; // Import your custom CSS
import { getPlayersByTeam, getPlayer } from '../services/playerservice'; // Make sure this import is correct

interface TeamMenuProps {
  currentTeam: string;
  setSelectedPlayer: React.Dispatch<React.SetStateAction<any>>; // Define setSelectedPlayer type
}

const TeamMenu: React.FC<TeamMenuProps> = ({ currentTeam, setSelectedPlayer }) => {
  const [current, setCurrent] = useState('mail');
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const nflTeams = {
    'ARI': '/images/ari.png',
    'ATL': '/images/atl.png',
    'BAL': '/images/bal.png',
    'BUF': '/images/buf.png',
    'CAR': '/images/car.png',
    'CHI': '/images/chi.png',
    'CIN': '/images/cin.png',
    'CLE': '/images/cle.png',
    'DAL': '/images/dal.png',
    'DEN': '/images/den.png',
    'DET': '/images/det.png',
    'GB': '/images/gb.png',
    'HOU': '/images/hou.png',
    'IND': '/images/ind.png',
    'JAX': '/images/jac.png',
    'KC': '/images/kc.png',
    'LVR': '/images/lv.png',
    'LAC': '/images/lac.png',
    'LAR': '/images/lar.png',
    'MIA': '/images/mia.png',
    'MIN': '/images/min.png',
    'NE': '/images/ne.png',
    'NO': '/images/no.png',
    'NYG': '/images/nyg.png',
    'NYJ': '/images/nyj.png',
    'PHI': '/images/phi.png',
    'PIT': '/images/pit.png',
    'SF': '/images/sf.png',
    'SEA': '/images/sea.png',
    'TB': '/images/tb.png',
    'TEN': '/images/ten.png',
    'WAS': '/images/was.png',
    'FA': '/images/nfl.png'
  };

  const sortedTeams = Object.keys(nflTeams).sort();

  const onClick: MenuProps['onClick'] = async (e) => {
    setCurrent(e.key);
    const selectedTeam = e.key.replace('team:', '');
    const players = await getPlayersByTeam(sortedTeams[selectedTeam]);
    if (players.length > 0) {
      const playerInfo = await getPlayer(players[0].id, false);
      setSelectedPlayer(playerInfo);
    }
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    setOpenKeys(keys);
  };

  const items: MenuProps['items'] = [
    {
      label: currentTeam,
      key: 'SubMenu',
      icon: <CaretDownOutlined />,
      children: sortedTeams.map((team, index) => ({
        label: team,
        key: `team:${index}`,
      })),
    },
  ];

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>
        <img 
          src={nflTeams[currentTeam]} 
          alt={`${currentTeam} logo`} 
          style={{ width: 'auto', height: '30px', alignItems: 'center', display: 'flex' }} 
        />
      </div>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              darkItemColor: 'rgba(211,212,213, 1)',
              darkGroupTitleColor: 'rgba(255, 0, 0, 0.65)',
              darkItemSelectedBg: 'transparent', // Add this line
              darkPopupBg: '#1b1b1b',
              itemHeight: 30,
            },
          },
        }}
      >
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
          theme="dark"
          style={{ width: '400px', background: 'none', fontSize: '1.4em', fontWeight: 'bold' }}
          triggerSubMenuAction="click"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        />
      </ConfigProvider>
    </div>
  );
};

export default TeamMenu;
