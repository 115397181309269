import React from 'react';
import performancepage from './../assets/images/Performance_Tab.png';
import PositionTabs from '../views/positiontabs';
import './../css/performance.css';

function PerformancePage({ players }) {
    return (
        <div className="performance-page">
            <img src={performancepage} alt="Performance Overview" className="performance-image" />
            
            {/* PositionTabs component to display tabs for each position */}
            <PositionTabs isNFL={true} />
        </div>
    );
}

export default PerformancePage;
