const baseURL = process.env.REACT_APP_API_URL;
const frontendToken = process.env.REACT_APP_FRONTEND_TOKEN;

export async function fetchRostershipData(username) {
  try {
    // Make the request to fetch rostership data (without triggering download)
    const response = await fetch(`${baseURL}/api/sync_rosters/${username}?doDownload=false`, {
      headers: {
        'X-Frontend-Token': frontendToken
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse and return the JSON response
    const jsonResponse = await response.json();
    return jsonResponse;  // Return the rostership data
  } catch (error) {
    console.error('Error fetching rostership data:', error);
  }
}

export async function downloadRostershipFile(username) {
  try {
    // Make the request to trigger the file download (doDownload=true)
    const response = await fetch(`${baseURL}/api/sync_rosters/${username}?doDownload=true`, {
      headers: {
        'X-Frontend-Token': frontendToken
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Get the blob object from the response
    const blob = await response.blob();

    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([blob]));

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'sleeper_sync.xlsx');  // Set the download attribute with desired file name
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up resources
    link.parentNode.removeChild(link);

    return true;
  } catch (error) {
    console.error('Error downloading the Excel file:', error);
  }
}




const SLEEPER_API_BASE_URL = 'https://api.sleeper.app/v1';

// Function to check if a Sleeper username exists
export const checkUsernameExists = async (username) => {
  try {
    const response = await fetch(`${SLEEPER_API_BASE_URL}/user/${username}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (data === null) {
      return null; // User does not exist
    }
    return data; // Return user data if exists
  } catch (error) {
    console.error('Error checking username:', error);
    throw error;
  }
};