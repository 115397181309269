import React, { useState, useEffect } from 'react';
import { Select, Button, Tooltip, Modal } from 'antd';
import { getPlayer, getAllPlayers } from './../services/playerservice';
import { getSimilaritiesByYear } from './../services/similarityservice';
import SimilarPlayerWidget from './../views/similarplayerwidget';
import { InfoCircleOutlined } from '@ant-design/icons';
import './../css/compare.css';
import similarityTab from './../assets/images/Similarity_Tab.png';
import devySimilarityTab from './../assets/images/Devy_Similarity_Tab.png';

import styles from './../css/playerspage.module.css'; // Convert CSS to module and import here


const { Option } = Select;

const statKeyMap = {
    PPG: 'ppg',
    TARGET_SHARE: 'targetshare',
    YPTPA: 'yptpa',
    TARGET_RATE: 'targetrate',
    YPRR: 'yprr',
    CARRY_PERCENT: 'carrypercent',
    YPTRA: 'yptra',
    PASSTD_PERCENT: 'passtdpercent',
    YPA: 'ypa',
    PPPG: 'pppg',
    RPPG: 'rppg',
    AY_A: 'ay_a',
    RUSH_TD_MS: 'rushtdms',
};

const getComparisonStats = (position) => {
    if (position === 'QB') return ["PPG", "PPPG", "RPPG", "AY_A", "PASSTD_PERCENT", "YPA", "CARRY_PERCENT", "YPTRA", "RUSHTD_MS"];
    if (position === 'RB') return ["PPG", "CARRY_PERCENT", "YPTRA", "RUSH_TD_MS", "TARGET_SHARE", "YPTPA"];
    if (position === 'WR') return ["PPG", "TARGET_SHARE", "YPTPA", "TARGET_RATE", "YPRR"];
    if (position === 'TE') return ["PPG", "TARGET_SHARE", "YPTPA", "TARGET_RATE", "YPRR"];
    return [];
};

const isAllZeroStats = (stats, statsToCompare) => {
    return statsToCompare.every(statKey => {
        const actualStatKey = statKeyMap[statKey];
        const value = stats[actualStatKey] || 0;
        return value === 0;
    });
};

const getStatsForYear = (player, year) => {
    return player.stats.find(stat => stat.calendaryear === year) || {};
};

const getAvailableYears = (player) => {
    console.log(player);
    const comparisonStats = getComparisonStats(player.player_info.position);

    return player.stats
        .filter(stat => {
            const yearStats = getStatsForYear(player, stat.calendaryear);
            return !isAllZeroStats(yearStats, comparisonStats);
        })
        .map(stat => ({
            label: `${stat.calendaryear} (Year ${stat.yearnum})`,
            value: String(stat.calendaryear)
        }))
        .sort((a, b) => a.value - b.value);
};

function SimilarPlayersPage({ players, isNFL = true }) {
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [availableYears, setAvailableYears] = useState([]);
    const [similarPlayers, setSimilarPlayers] = useState([]);
    const [allPlayers, setAllPlayers] = useState(null);
    const [error, setError] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [infoModalVisible, setInfoModalVisible] = useState(false);

    const handleSimilarPlayerChange = async (value) => {
        const selectedPlayer = playersList.find(player => player.id === value);
        setSelectedPlayer(selectedPlayer);
        setSelectedYear(null);
        setSimilarPlayers([]);
        setError('');

        if (selectedPlayer) {
            try {
                const playerData = await getPlayer(selectedPlayer.id, !isNFL);
                const yearsWithStats = getAvailableYears(playerData);
                setAvailableYears(yearsWithStats);
            } catch (error) {
                console.error('Error fetching player stats:', error);
                setAvailableYears([]);
            }
        }
    };

    useEffect(() => {
        async function fetchPlayers() {
            try {
                const data = await getAllPlayers(isNFL);
                setAllPlayers(data);
            } catch (error) {
                console.error('Error fetching players:', error);
            }
        }
        fetchPlayers();
    }, []);

    const handleSeeSimilarClick = async () => {
        if (selectedPlayer && selectedYear) {
            try {
                const response = await getSimilaritiesByYear(selectedPlayer.id, selectedYear, isNFL);
                if (response.success) {
                    setSimilarPlayers(response.data);
                    setError('');
                } else {
                    setError(response.message);
                    setSimilarPlayers([]);
                }
            } catch (error) {
                console.error('Error fetching similar players:', error);
                setError('Failed to fetch similar players');
                setSimilarPlayers([]);
            }
        }
    };

    const sortedPlayers = (list) => {
        return [...list].sort((a, b) => {
            const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
            const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });
    };

    const playersList = allPlayers ? sortedPlayers(allPlayers) : sortedPlayers(players);

    return (
        <div className="similar-players-page">
            {isNFL ? (
                <img
                    src={similarityTab}
                    alt="Randall Cobb"
                    className={styles.performanceImage}
                />
            ) : (
                <img
                    src={devySimilarityTab}
                    alt="Devy Player"
                    className={styles.performanceImage}
                />
            )}


            <div className="similarity-tool-container">
                <div className="similarity-tool-text">
                    <h2 className="similarity-tool-title">SIMILARITY TOOL</h2>
                    <p className="similarity-tool-description">
                        Our similarity tool leverages advanced metrics to compare individual player seasons against every other season in our database. This comparison provides users with a historical perspective, helping them understand how a specific season stacks up in context.
                    </p>
                </div>

                <div className="similarity-tool-selection">
                    <Select
                        showSearch
                        placeholder="Select Player"
                        style={{ width: 240, marginBottom: '10px' }}
                        onChange={handleSimilarPlayerChange}
                        value={selectedPlayer ? selectedPlayer.id : null}
                        filterOption={(input, option) => {
                            const playerName = option.label.toLowerCase();
                            return playerName.includes(input.toLowerCase());
                        }}
                    >
                        {playersList.map((player) => (
                            <Option key={player.id} value={player.id} label={`${player.first_name} ${player.last_name}`}>
                                {player.first_name} {player.last_name}
                            </Option>
                        ))}
                    </Select>

                    {selectedPlayer && availableYears.length === 0 && (
                        <div style={{ marginBottom: '10px' }}>
                            No eligible seasons
                            <Tooltip title="Click for more info">
                                <InfoCircleOutlined
                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    onClick={() => setIsModalVisible(true)}
                                />
                            </Tooltip>
                        </div>
                    )}

                    {availableYears.length > 0 && (
                        <>
                            <Select
                                showSearch
                                placeholder="Select Year"
                                style={{ width: 120, marginBottom: '10px' }}
                                onChange={setSelectedYear}
                                value={selectedYear}
                                disabled={!selectedPlayer}
                            >
                                {availableYears.map(year => (
                                    <Select.Option key={year.value} value={year.value}>
                                        {year.label}
                                    </Select.Option>
                                ))}
                            </Select>
                            <div style={{ marginBottom: '10px' }}>
                                Not seeing a season?
                                <Tooltip title="Click for more info">
                                    <InfoCircleOutlined
                                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                                        onClick={() => setInfoModalVisible(true)}
                                    />
                                </Tooltip>
                            </div>
                        </>
                    )}

                    {selectedYear && (
                        <Button
                            className="submit-button"
                            type="primary"
                            onClick={handleSeeSimilarClick}
                            disabled={!selectedPlayer || !selectedYear}
                        >
                            See similar players
                        </Button>
                    )}
                </div>
            </div>

            <SimilarPlayerWidget similarPlayers={similarPlayers} selectedPlayer={selectedPlayer} selectedYear={selectedYear} map={availableYears} />

            {error && (
                <div style={{ marginTop: '20px', color: 'red' }}>
                    <p>{error}</p>
                </div>
            )}

            <Modal
                title="No Eligible Seasons"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => setIsModalVisible(false)}
            >
                <p>This player has no seasons with sufficient data for a valid comparison. Please try selecting a different player or check for updates in the future.</p>
            </Modal>

            <Modal
                title="Missing Seasons"
                visible={infoModalVisible}
                onCancel={() => setInfoModalVisible(false)}
                onOk={() => setInfoModalVisible(false)}
            >
                <p>If you are missing a season, it likely isn't eligible due to the number of games played. Ineligible seasons are hidden to ensure a sufficient sample size for meaningful comparisons. If the current year is missing, check back around week 7 or 8 to find it.</p>
            </Modal>

            <div style={{ height: '60px' }}></div>
        </div>
    );
}

export default SimilarPlayersPage;
