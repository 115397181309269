import React, { useEffect, useRef } from 'react';
import styles from './../css/gamesdisplay.module.css';

const GamesDisplay = ({ gamesData = [] }) => {

    const containerRef = useRef(null);
    const currentScrollIndex = useRef(0); // Persist scroll index across re-renders

    useEffect(() => {
    if (containerRef.current && gamesData.length > 0) {
        const container = containerRef.current;
        const gameHeight = 88;
        const totalGames = gamesData.length;

        const scrollNext = () => {
            if (currentScrollIndex.current >= totalGames) {
                container.style.transition = 'none';
                container.style.transform = `translateY(0px)`;
                currentScrollIndex.current = 0;
                requestAnimationFrame(() => {
                    container.style.transition = 'transform 0.8s ease-in-out';
                });
                return;
            }

            container.style.transform = `translateY(-${gameHeight * (currentScrollIndex.current + 1)}px)`;
            currentScrollIndex.current++;
        };

        const interval = setInterval(scrollNext, 5000);
        return () => clearInterval(interval);
    }
}, [gamesData]);





    // Duplicate gamesData for seamless looping
    const loopedGames = [...gamesData, ...gamesData];

    return (
        <div className={styles.gamesDisplay} >
            <div className={styles.scrollContainer} ref={containerRef}>
                {loopedGames.map((game, index) => (
                    <div
                        key={index}
                        className={styles.gameItem}
                    >
                        <div className={styles.gameRow}>
                            <div className={styles.team}>
                                <span className={styles.abbreviation}>{game.teams[0].abbreviation}</span>
                                <div
                                    className={`${styles.teamLogoWrapper} ${game.teams[0].abbreviation.toLowerCase() === 'wsh' ? styles.smallLogo : ''}`}
                                >
                                    <img
                                        src={`/images/${game.teams[0].abbreviation.toLowerCase()}.png`}
                                        alt={`${game.teams[0].name} logo`}
                                        className={styles.teamLogo}
                                    />
                                </div>
                            </div>

                            {/* Score */}
                            <div className={styles.scoreWrapper}>
                                <div className={styles.score}>
                                    <span>{game.teams[0].score}</span>
                                    <span>-</span>
                                    <span>{game.teams[1].score}</span>
                                </div>
                                {game.state === "pre" && (
                                    <div className={styles.shortDetail}>
                                        {game.shortDetail.replace(/(\d{1,2}:\d{2})\s([AP]M)/, "$1$2")}
                                    </div>
                                )}

                                {game.state === "in" && (
                                    <div className={styles.shortDetail}>
                                        {`LIVE: ${game.shortDetail}`}
                                        <span className={styles.pulsingDot}></span> {/* Add pulsing red circle */}
                                    </div>
                                )}

                                {game.state === "post" && (
                                    <div className={styles.shortDetail}>
                                        {`${game.shortDetail}`} {/* Display final status */}
                                    </div>
                                )}
                            </div>

                            <div className={styles.team}>
                                <div className={styles.teamLogoWrapper}>
                                    <img
                                        src={`/images/${game.teams[1].abbreviation.toLowerCase()}.png`}
                                        alt={`${game.teams[1].name} logo`}
                                        className={styles.teamLogo}
                                    />
                                </div>
                                <span className={styles.abbreviation}>{game.teams[1].abbreviation}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GamesDisplay;
