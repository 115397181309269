import styles from './../css/rostershippage.module.css'; // Import the CSS module
import { useState } from 'react';
import { Button, Checkbox, Input, message, Spin } from "antd";
import { fetchRostershipData, downloadRostershipFile, checkUsernameExists } from './../services/rostershipservice';
import React from 'react';
import RostershipTable from '../views/rostershiptable';
import rostershiptab from './../assets/images/Rostership_Tab.png'; // Importing the image

function RostershipPage() {
  const [username, setUsername] = useState('');
  const [isDownloadChecked, setIsDownloadChecked] = useState(false);
  const [rostershipData, setRostershipData] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleVerifyUsername = async () => {
    setLoading(true);

    try {
      const response = await checkUsernameExists(username);

      if (response === null) {
        message.error('User does not exist.');
        setLoading(false);
        return;
      }

      const data = await fetchRostershipData(username);

      if (data) {
        setRostershipData(data);
        message.success('Rostership data fetched successfully.');

        if (isDownloadChecked) {
          const didDownload = await downloadRostershipFile(username);
          if (didDownload) {
            message.success('Excel downloading...');
          }
        }
      } else {
        message.error('Failed to fetch rostership data.');
      }
    } catch (error) {
      console.error('Error verifying username:', error);
      message.error('Failed to verify username. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUsername(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setIsDownloadChecked(e.target.checked);
  };

  return (
    <div className={styles.rostershipPage}>
      <img
        src={rostershiptab}
        alt="Rostership"
        className={styles.performanceImage}
      />

      <div className={styles.rostershipRow}>
        <div className={styles.playerstooltext}>
          <h2 className={styles.playerstooltitle}>ROSTERSHIP TOOL</h2>
          <p className={styles.playerstooldescription}>
            Easily track your player exposure across all your Sleeper leagues with the Rostership Tool.
            Gain valuable insights into which players dominate your rosters, helping you make more informed
            decisions about trades, waiver pickups, and overall team strategy. Stay ahead in your fantasy
            leagues by understanding your roster composition at a glance.
          </p>
        </div>

        <div className={styles.inputSection}>
          <p>Enter your sleeper username below:</p>
          <div className={styles.center}>
            <Input
              className={styles.input} // Apply the custom input style
              value={username}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.buttonRow}>
            <Button className={styles.seerostershipbutton} onClick={handleVerifyUsername}>
              See Rostership
            </Button>
          </div>
          <div className={styles.checkboxRow}>
            <Checkbox
              className={styles.checkbox}
              checked={isDownloadChecked}
              onChange={handleCheckboxChange}
            >
              Download?
            </Checkbox>
          </div>
        </div>

      </div>

      <Spin spinning={loading}>
        {rostershipData && <RostershipTable rostershipData={rostershipData} />}
      </Spin>
    </div>

  );
}

export default RostershipPage;
